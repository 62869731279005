import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { Observable, catchError, tap } from 'rxjs';

import { AtividadeCargo } from '@app/core/models/perfil-profissiografico/atividade-cargo';
import { Location } from '@angular/common';
import { MessageService } from 'primeng/api';
import { PerfilProfissiograficoApiService } from '@app/core/services/perfil-profissiografico-api.service';
import { QuestionarioCargo } from '@app/core/models/perfil-profissiografico/questionario-cargo';

@Component({
	selector: 'sgp-detalhar-questionario',
	templateUrl: './detalhar-questionario.component.html'
})
export class DetalharQuestionarioComponent implements OnInit {
	questionarioCargo$?: Observable<QuestionarioCargo>;
	atividadesCargo$?: Observable<AtividadeCargo>;
	idCargo?: number;
	origemPesquisarQuestionario?: boolean;

	constructor(
		private perfilProfissiograficoApi: PerfilProfissiograficoApiService,
		private route: ActivatedRoute,
		private router: Router,
		private messageService: MessageService,
		private location: Location
	) {}

	ngOnInit(): void {
		this.obterIdCargo();
		this.obterOrigem();
		this.detalharQuestionarioCargo(this.idCargo!);
		this.detalharAtividadesCargo(this.idCargo!);
	}

	detalharQuestionarioCargo(idCargo: number) {
		this.questionarioCargo$ = this.perfilProfissiograficoApi.detalharCargo(idCargo).pipe(
			catchError(error => {
				this.handleError(error.error.message, '');
				throw error;
			}) /*,
			tap(x => console.log('Data Inicio Liberação: ', x.dataInicioLiberacao, x.dataInicioLiberacao?.length))*/
		);
	}

	private detalharAtividadesCargo(idCargo: number) {
		this.atividadesCargo$ = this.perfilProfissiograficoApi.detalharAtividadesCargo(idCargo).pipe(
			catchError(error => {
				this.handleError(error.error.message, '');
				throw error;
			}) /*,
			tap(x => console.log('Data Inicio Liberação: ', x.dataInicioLiberacao, x.dataInicioLiberacao?.length))*/
		);
	}

	voltar(): void {
		if (this.origemPesquisarQuestionario) {
			this.router.navigate(['perfilProfissiografico/pesquisarQuestionarioProfissiografico'], 
				{ queryParams: { preservarParametros: true }
			});
			return;
		}
		this.location.back();
	}

	existeDataLiberacao(dataLiberacao: string[]): boolean {
		return dataLiberacao.length > 0 && dataLiberacao[0] !== null;
	}

	private obterIdCargo() {
		this.idCargo = Number(this.route.snapshot.paramMap.get('idCargo'));
	}

	private obterOrigem() {
		const navigation = this.router.getCurrentNavigation() ?? this.router.lastSuccessfulNavigation;
		this.origemPesquisarQuestionario = navigation?.extras?.state?.origem == 'pesquisarQuestionario' ? true : false;
	}

	private handleError(descricao: string, detalhe: string) {
		this.messageService.add({
			severity: 'error',
			summary: descricao,
			detail: detalhe
		});
	}
}
