<!-- <div class="bg-yellow-10 my-5 p-2"><pre>{{ pesquisarService.filtro | json}}</pre></div> -->

<div *ngIf="(pesquisarService.resultado$ | async) as resultado">
	<div class="br-table medium mt-4" *ngIf="resultado.items!.length; else semResultado">
		<div class="table-header">
			<div class="top-bar row">
				<div class="col-sm-6 text-semi-bold">Foram encontrados {{ resultado.metadata?.total }} candidatos</div>
				<div *ngIf="!pesquisarService.somenteGestorPessoas" class="col-sm-6 text-right">
					<p-button
						styleClass="primary"
						(click)="atribuirEmLote()"
						data-cy="botao-atribuir-lote"
						[disabled]="candidatosSelecionados.length === 0"
						>Atribuir múltiplos relatórios para mim</p-button
					>
				</div>
			</div>
		</div>

		<p-table
			[value]="resultado.items!"
			[lazy]="true"
			(onPage)="paginar($event)"
			[first]="pesquisarService.filtro.first!"
			[totalRecords]="resultado.metadata?.total!"
			dataKey="idCandidato"
			[(selection)]="candidatosSelecionados"
			[rowHover]="true"
			responsiveLayout="stack"
			[autoLayout]="true"
			[paginator]="true"
			[rows]="pesquisarService.filtro.size"
			[rowsPerPageOptions]="[10,20,50,100]"
			data-selection="data-selection"
			data-cy="lista-candidatos-laudo"
		>
			<ng-template pTemplate="header">
				<tr>
					<th *ngIf="!pesquisarService.somenteGestorPessoas" id="checkbox" class="checkbox">
						<p-tableHeaderCheckbox></p-tableHeaderCheckbox>
					</th>
					<th id="acoes">Ações</th>
					<th id="candidato">Informações do candidato</th>
					<th id="situacao">Situação do relatório</th>
					<th id="datas">Datas</th>
				</tr>
			</ng-template>
			<ng-template pTemplate="body" let-candidato let-rowIndex="rowIndex">
				<tr>
					<td *ngIf="!pesquisarService.somenteGestorPessoas" class="checkbox" width="5%" style="vertical-align: baseline">
						<p-tableCheckbox
							[value]="candidato"
							[disabled]="!permiteSelecionarParaAtribuirEmLote(candidato)"
							[index]="rowIndex"
						></p-tableCheckbox>
					</td>
					<td width="5%" style="vertical-align: baseline">
						<sgp-botao-acao-profissiografico
							[listaAcoes]="candidato.acoesPossiveis"
							[idCandidato]="candidato.idCandidato"
							[idCargo]="candidato.idCargo"
							origemLaudo="pesquisarLaudo"
							[situacaoLaudo]="candidato.situacao.codigo"
							(acaoRealizada)="pesquisarService.pesquisar()"
						/>
					</td>
					<td width="50%" style="vertical-align: baseline">
						<div class="text-up-02">
							{{ candidato.nomeCandidato | titlecase | imprimirDash}}
							<i
								class="fas fa-exclamation-circle text-up-03 text-orange-30"
								*ngIf="candidato.dataSolicitacaoPrioridade"
								alt="Prioridade"
								title="Prioridade"
							></i>
						</div>
						<div class="mt-2">
							<span class="text-semi-bold">CPF:</span> {{candidato.cpfCandidato.toString() | sgpMascaraCpf}}
						</div>
						<div class="mt-2">
							<span class="text-semi-bold">Órgão:</span> {{ candidato.nomeOrgao | titlecase | imprimirDash }}
						</div>
						<div class="mt-2">
							<span class="text-semi-bold">Cargo:</span> {{ candidato.nomeCargo | titlecase | imprimirDash }}
						</div>
						<div class="mt-2">
							<span class="text-semi-bold">Responsável:</span> {{ candidato.responsavelLaudo | titlecase | imprimirDash }}
						</div>
					</td>
					<td width="20%" style="vertical-align: baseline">
						<div class="br-tag text {{candidato.situacao.codigo}}">
							<span class="text-base text-base">{{candidato.situacao.descricao}}</span>
						</div>
					</td>
					<td width="20%" style="vertical-align: baseline">
						<div class="mt-2" *ngIf="candidato.dataEnvioQuestionario">
							<span class="text-semi-bold">Envio do questionário:</span> {{ candidato.dataEnvioQuestionario | date:
							'dd/MM/YYYY' }}
						</div>
						<div class="mt-2" *ngIf="candidato.dataSolicitacaoPrioridade">
							<span class="text-semi-bold">Solicitação de prioridade:</span> {{ candidato.dataSolicitacaoPrioridade | date:
							'dd/MM/YYYY'}}
						</div>
						<div class="mt-2" *ngIf="candidato.dataGeracaoLaudo">
							<span class="text-semi-bold">Geração do relatório:</span> {{ candidato.dataGeracaoLaudo | date: 'dd/MM/YYYY'}}
						</div>
						<div class="mt-2" *ngIf="candidato.dataEmissaoLaudo">
							<span class="text-semi-bold">Emissão do relatório:</span> {{ candidato.dataEmissaoLaudo | date: 'dd/MM/YYYY' }}
						</div>
						<div class="mt-2" *ngIf="candidato.dataCienciaLaudo">
							<span class="text-semi-bold">Ciência do relatório:</span> {{ candidato.dataCienciaLaudo | date: 'dd/MM/YYYY' }}
						</div>
					</td>
				</tr>
			</ng-template>
		</p-table>
	</div>
	<ng-template #semResultado>
		<sgp-nenhum-resultado [exibir]="true" />
	</ng-template>
</div>
