import * as moment from 'moment';

import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { ActivatedRoute } from '@angular/router';
import { FiltroPesquisarLaudo } from '@app/core/models/perfil-profissiografico/filtro-pesquisar-laudo';
import { Observable } from 'rxjs';
import { OportunidadesApiService } from '@app/core/services/oportunidades-api.service';
import { PerfisEnum } from '@app/core/enums/perfis.enum';
import { PesquisarLaudoService } from '../services/pesquisar-laudo.service';
import { UsuarioService } from '@sigepe/sigepe-template';

@Component({
	selector: 'sgp-filtro-pesquisar-laudo',
	templateUrl: './filtro-pesquisar-laudo.component.html'
})
export class FiltroPesquisarLaudoComponent implements OnInit {
	form!: FormGroup;
	submetido = false;
	orgaoUsuario$?: Observable<any>;
	periodoEnvio: Date[] = [];
	periodoGeracao: Date[] = [];
	periodoEmissao: Date[] = [];
	perfisEnum = PerfisEnum;
	placeholderCargo = 'Selecione um órgão para habilitar o campo.';
	somenteGestorPessoas = false;
	codigoOrgao : number | undefined = undefined;
	perfisUsuario?: string[];

	constructor(
		protected pesquisarService: PesquisarLaudoService,
		private fb: FormBuilder,
		private route: ActivatedRoute,
		private usuarioService: UsuarioService,
		private oportunidadesService: OportunidadesApiService
	) {}

	ngOnInit() {
		this.somenteGestorPessoas = false;
		this.verificarSeGestorPessoas();
		this.preencherDatas();
		this.criarFormulario();
		this.limparDadosSeUrlAnteriorNaoForDetalharLaudo();
		this.obterNomeOrgaoUsuario();
		this.desabilitarCampos();
		this.carregarCombos();
		if (this.somenteGestorPessoas) {
			this.placeholderCargo = 'Selecione um concurso para habilitar o campo.';
		} else {
			this.placeholderCargo = 'Selecione um órgão para habilitar o campo.';
		}
	}

	pesquisar() {
		this.submetido = true;
		if (this.form.valid) {
			this.preparaFiltro();
			this.pesquisarService.pesquisar();
			this.submetido = false;
		} else {
			this.form.markAllAsTouched();
		}
	}

	limpar() {
		this.submetido = false;
		this.form.reset();
		this.desabilitarCampos();
		this.form.get('idConcurso')?.enable();
		this.pesquisarService.limpar();

		if (this.somenteGestorPessoas && !this.form.get('codigoOrgao')?.value) {
			this.form.get('codigoOrgao')?.setValue(this.codigoOrgao);
			this.pesquisarService.filtro.codigoOrgao = this.codigoOrgao;
		}
	}

	selecionarConcurso(evento: any) {
		const idConcurso = evento.value;

		this.pesquisarService.filtro.idConcurso = idConcurso;
		if (!this.somenteGestorPessoas) {
			this.form.get('codigoOrgao')?.reset();
			if (idConcurso != null) {
				this.form.get('codigoOrgao')?.reset();
				this.form.get('codigoOrgao')?.enable();
				this.pesquisarService.listarOrgaosConcurso(idConcurso);
			} else {
				this.pesquisarService.orgaos$ = undefined;
				this.form.get('codigoOrgao')?.disable();
			}
		} else if (idConcurso != null) {
				this.form.get('codigoCargo')?.enable();
				this.pesquisarService.listarCargos(idConcurso, this.pesquisarService.filtro.codigoOrgao!);
		}
		if (!idConcurso || !this.somenteGestorPessoas) {
			this.pesquisarService.cargos$ = undefined;
			this.form.get('codigoCargo')?.disable();
		}
		this.form.get('codigoCargo')?.reset();
	}

	selecionarOrgao(codigoOrgao: any) {
		this.form.get('codigoCargo')?.reset();
		if (codigoOrgao != null && this.form.get('idConcurso')!.value) {
			this.pesquisarService.listarCargos(this.form.get('idConcurso')!.value, codigoOrgao);
			this.form.get('codigoCargo')?.enable();
		} else {
			this.pesquisarService.cargos$ = undefined;
			this.form.get('codigoCargo')?.disable();
		}
	}

	private preencherDatas() {
		if (this.pesquisarService.filtro.dataInicioEnvio && this.pesquisarService.filtro.dataFimEnvio) {
			this.periodoEnvio = [
				this.prepararDataForm(this.pesquisarService.filtro.dataInicioEnvio),
				this.prepararDataForm(this.pesquisarService.filtro.dataFimEnvio)
			];
		}
		if (this.pesquisarService.filtro.dataInicioGeracao && this.pesquisarService.filtro.dataFimGeracao) {
			this.periodoGeracao = [
				this.prepararDataForm(this.pesquisarService.filtro.dataInicioGeracao),
				this.prepararDataForm(this.pesquisarService.filtro.dataFimGeracao)
			];
		}
		if (this.pesquisarService.filtro.dataInicioEmissao && this.pesquisarService.filtro.dataFimEmissao) {
			this.periodoEmissao = [
				this.prepararDataForm(this.pesquisarService.filtro.dataInicioEmissao),
				this.prepararDataForm(this.pesquisarService.filtro.dataFimEmissao)
			];
		}
	}

	private criarFormulario() {
		this.form = this.fb.group(
			{
				idConcurso: [this.pesquisarService.filtro.idConcurso, Validators.required],
				codigoOrgao: [this.pesquisarService.filtro.codigoOrgao],
				codigoCargo: [this.pesquisarService.filtro.codigoCargo],
				situacao: [this.pesquisarService.filtro.situacao],
				cpfCandidato: [this.pesquisarService.filtro.cpfCandidato],
				periodoEnvio: [this.periodoEnvio],
				periodoGeracao: [this.periodoGeracao],
				periodoEmissao: [this.periodoEmissao],
				prioridade: [this.pesquisarService.filtro.prioridade],
				atribuidosMim: [this.pesquisarService.filtro.atribuidosMim]
			},
			{
				validators: [
					this.validarPeriodo('periodoEnvio'),
					this.validarPeriodo('periodoGeracao'),
					this.validarPeriodo('periodoEmissao')
				]
			}
		);
	}

	private validarPeriodo(campo: string) {
		return (formGroup: FormGroup) => {
			if (formGroup.get(campo)?.value != null) {
				const dataInicio = formGroup.get(campo)?.value[0];
				const dataFim = formGroup.get(campo)?.value[1];
				if (dataInicio && !dataFim) {
					formGroup.get(campo)?.setErrors({ invalido: true });
				}
			}
		};
	}

	private desabilitarCampos() {
		if (!this.somenteGestorPessoas) {
			this.form.get('codigoOrgao')?.disable();
		}
		this.form.get('codigoCargo')?.disable();
		
	}

	private carregarCombos() {
		if (!this.pesquisarService.concursos$) {
			this.pesquisarService.listarConcursos();
		}
		if (!this.pesquisarService.situacoes$) {
			this.pesquisarService.listarSituacoes();
		}
		this.pesquisarService.situacoes$?.subscribe(
			situacoes => {
				if (situacoes) {
					const found = situacoes.find((element) => element.codigo === 'todos');
					if (!found)  {
						situacoes.unshift({codigo: 'todos', descricao: 'Todos'});
					}
				}
		});
	}

	private preparaFiltro() {
		this.pesquisarService.filtro = new FiltroPesquisarLaudo();
		this.pesquisarService.filtro.idConcurso = this.obterValor('idConcurso');
		this.pesquisarService.filtro.codigoOrgao = this.obterValor('codigoOrgao');
		if (!this.pesquisarService.filtro.codigoOrgao && this.somenteGestorPessoas)
			this.pesquisarService.filtro.codigoOrgao = this.codigoOrgao;	
		this.pesquisarService.filtro.codigoCargo = this.obterValor('codigoCargo');
		this.pesquisarService.filtro.situacao = this.obterValor('situacao');
		this.pesquisarService.filtro.cpfCandidato = this.obterValorCPF('cpfCandidato');
		if (this.obterValor('periodoEnvio') != null) {
			this.pesquisarService.filtro.dataInicioEnvio = this.prepararDataFiltro(this.obterValor('periodoEnvio')[0]);
			this.pesquisarService.filtro.dataFimEnvio = this.prepararDataFiltro(this.obterValor('periodoEnvio')[1]);
		}
		if (this.obterValor('periodoGeracao') != null) {
			this.pesquisarService.filtro.dataInicioGeracao = this.prepararDataFiltro(this.obterValor('periodoGeracao')[0]);
			this.pesquisarService.filtro.dataFimGeracao = this.prepararDataFiltro(this.obterValor('periodoGeracao')[1]);
		}
		if (this.obterValor('periodoEmissao') != null) {
			this.pesquisarService.filtro.dataInicioEmissao = this.prepararDataFiltro(this.obterValor('periodoEmissao')[0]);
			this.pesquisarService.filtro.dataFimEmissao = this.prepararDataFiltro(this.obterValor('periodoEmissao')[1]);
		}
		this.pesquisarService.filtro.prioridade = this.obterValor('prioridade');
		this.pesquisarService.filtro.atribuidosMim = this.obterValor('atribuidosMim');
	}

	private obterValor(campo: string): any {
		const valor = this.form.get(campo)?.value;
		if (campo == 'situacao' && valor == 'todos')
			return null;
		return valor;
	}
	private obterValorCPF(campo: string): any {
		if (this.form.get(campo)?.value) {
			const valor = this.form.get(campo)?.value.toString();
			const numero = valor.replaceAll('.', '').replaceAll('-', '');
			return numero;
		} else {
			return this.form.get(campo)?.value;
		}
	}

	private prepararDataFiltro(data: Date): string | undefined {
		return data != null ? moment(data).format('DD/MM/YYYY') : undefined;
	}

	private prepararDataForm(data?: string): Date {
		return moment(data, 'DD/MM/YYYY').toDate();
	}

	private obterNomeOrgaoUsuario() {
		if (this.somenteGestorPessoas) {
			this.codigoOrgao = this.usuarioService.getUsuario().codigoOrgaoSiape;
			this.orgaoUsuario$ = this.oportunidadesService.consultarOrgao(this.codigoOrgao!.toString());
			this.form.get('codigoOrgao')?.setValue(this.codigoOrgao);
			this.pesquisarService.filtro.codigoOrgao = this.codigoOrgao;
			this.selecionarOrgao(this.codigoOrgao);
		} else if (this.pesquisarService.filtro.codigoOrgao) {
			this.codigoOrgao = 0;
			this.form.controls['codigoOrgao'].setValue(this.pesquisarService.filtro.codigoOrgao);
			this.selecionarOrgao(this.pesquisarService.filtro.codigoOrgao);
		}
	}

	private limparDadosSeUrlAnteriorNaoForDetalharLaudo(): void {
		this.route.queryParams.subscribe(params => {
			console.log('Parâmetros da URL:', params);
			if (!(params['preservarParametros'] === 'true')) {
				console.log('O parâmetro preservarParametros não está presente ou é false');
				this.limpar();
			}
		});
	}

	private verificarSeGestorPessoas() {
		this.perfisUsuario = this.usuarioService.getUsuario().perfis;
		if (this.perfisUsuario?.includes(PerfisEnum.GESTOR_PESSOAS) && !this.perfisUsuario?.includes(PerfisEnum.GESTOR_PRODUTO) && !this.perfisUsuario?.includes(PerfisEnum.PESQUISADOR)) {
			     this.somenteGestorPessoas = true;
		}
		this.pesquisarService.somenteGestorPessoas = this.somenteGestorPessoas;
	}
}
